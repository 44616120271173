:root {
  --shadow-ai-md: 0px 1px 4px 2px rgba(196, 187, 204, 0.8);
  --shadow-ai-xl:
    0px 17px 82px 44px rgba(83, 76, 120, 0.29), 0px 13px 26px 0px rgba(146, 23, 180, 0.2);
  --shadow-box: 0px 2px 2px 0px rgba(220, 227, 235, 0.4);
  --shadow-dropdown: 0px 1px 3px 1px rgba(37, 50, 60, 0.2);
  --shadow-lg: 0px 2px 4px 0px rgba(127, 145, 163, 0.2);
  --shadow-md: 0px 1px 2px 0px rgba(127, 145, 163, 0.2);
  --shadow-modal:
    0px 26px 36px -1px rgba(23, 34, 46, 0.15), 0px 11px 12px 0px rgba(23, 34, 46, 0.15),
    0px 0px 0px 1px rgba(35, 62, 79, 0.1);
  --shadow-navbar-item:
    0px 1px 2px 0px rgba(213, 224, 235, 0.8), 0px 0px 4px 1px rgba(213, 224, 235, 0.4);
  --shadow-popover:
    0px 10px 12px -1px rgba(23, 34, 46, 0.15), 0px 3px 4px 0px rgba(23, 34, 46, 0.15),
    0px 0px 0px 1px rgba(35, 62, 79, 0.1);
  --shadow-sm: 0px 1px 2px 0px rgba(127, 145, 163, 0.1);
  --shadow-sticky-header:
    0px 0px 10px 0px rgba(23, 34, 46, 0.15), 0px 1px 1px -1px rgba(23, 34, 46, 0.4);
}
